import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaLocationArrow, FaGraduationCap, FaPills, FaDog } from 'react-icons/fa';
import { DiJsBadge, DiHtml5, DiPython, DiReact, DiNodejs } from "react-icons/di";
import { SiFlask, SiAdobexd, SiMysql, SiSwift, SiXcode } from "react-icons/si";
import { VscCode, VscGithubInverted } from "react-icons/vsc";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Fade from 'react-reveal/Fade';


function About() {
    return (
        <div class="section" id="about">
                <Row>
                 <Col>

            <div class="pills">
            <Fade left>    
            <h1 className="primaryTxt text-center">About Me</h1>
                <div class="mx-2">
                <FaLocationArrow className="icon"/>
                
                <p className="secondaryTxt"> Hi, my name is Cameron Baffuto. I am a Software Engineer located in Princeton, NJ USA</p>
                
                <FaGraduationCap className="icon"/>
                
                <p className="secondaryTxt">I recieved my Masters of Software Engineering from Drexel University in 2020</p>
                
                <FaPills className="icon"/>
                
                <p className="secondaryTxt">Recently, I have been working in the Pharmaceutical industry innovating content capabilities</p>
               
                <FaDog className="icon"/>
                
                <p className="secondaryTxt">In my free time, I love to play soccer, and hangout with my four legged, fluffy best buddies Nash and Violet!</p>
                </div>
                </Fade>
            </div>

            </Col>

            <Col>
            
            <div class="pills mx-3">
              <Fade right>  
            <h1 className="primaryTxt text-center">Languages</h1>
                <p className="whiteIcon text-center">
                <OverlayTrigger overlay={(<Tooltip>Javascript</Tooltip>)} placement="top">
                    <span><DiJsBadge /> </span>
                </OverlayTrigger>

                <OverlayTrigger overlay={(<Tooltip>HTML5</Tooltip>)} placement="top">
                    <span><DiHtml5/> </span>
                </OverlayTrigger>

                <OverlayTrigger overlay={(<Tooltip>Swift</Tooltip>)} placement="top">    
                    <span><SiSwift /> </span>
                </OverlayTrigger>

                <OverlayTrigger overlay={(<Tooltip>Python</Tooltip>)} placement="top">    
                    <span><DiPython /> </span>
                </OverlayTrigger>

                <OverlayTrigger overlay={(<Tooltip>SQL</Tooltip>)} placement="top">    
                    <span><SiMysql /> </span>
                </OverlayTrigger>    
                </p> 
                </Fade>  
            </div>
            
            <div class="pills m-3">
                <Fade right>
            <h1 className="primaryTxt text-center">Frameworks</h1>
                <p className="whiteIcon text-center">
                <OverlayTrigger overlay={(<Tooltip>React</Tooltip>)} placement="top">
                    <span><DiReact /> </span>
                </OverlayTrigger>

                <OverlayTrigger overlay={(<Tooltip>Node JS</Tooltip>)} placement="top">
                    <span><DiNodejs/> </span>
                </OverlayTrigger>

                <OverlayTrigger overlay={(<Tooltip>Flask</Tooltip>)} placement="top">
                    <span><SiFlask /> </span>
                </OverlayTrigger>  
                </p>
                </Fade>
            </div>
            
            <div class="pills m-3">
                <Fade right>
            <h1 className="primaryTxt text-center">Tools</h1>
                <p className="whiteIcon text-center">
                <OverlayTrigger overlay={(<Tooltip>VS Code</Tooltip>)} placement="top">
                    <span><VscCode /> </span>
                </OverlayTrigger>

                <OverlayTrigger overlay={(<Tooltip>Adobe XD</Tooltip>)} placement="top">
                    <span><SiAdobexd /> </span>
                </OverlayTrigger>

                <OverlayTrigger overlay={(<Tooltip>Xcode</Tooltip>)} placement="top">
                    <span><SiXcode /> </span>
                </OverlayTrigger>

                <OverlayTrigger overlay={(<Tooltip>Github</Tooltip>)} placement="top">
                    <span><VscGithubInverted /> </span>
                </OverlayTrigger>
                </p> 
                </Fade>   
            </div>

            </Col>

            </Row>
            

        </div>
    )
}

export default About
