import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "./assets/logo.png";
import { Link, animateScroll as scroll } from "react-scroll";
import "./App.css";
// import Pdf from '../src/assets/baffutoResume.pdf'

function Navigation() {

  const [expanded, setExpanded] = useState(false);

  return (
    <div>
    <Navbar className="darkBlue" expand="lg" variant="dark" fixed="top" expanded={expanded}>
      <Container>
        <Navbar.Brand>
          <img
            src={Logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
            alt="logo"
            onClick={() => scroll.scrollToTop()}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <Nav.Link className="nav-links" as={Link} to="about" smooth={true} duration={500} spy={true} offset={-70} onClick={() => setExpanded(false)}>About</Nav.Link>
            {/* <Nav.Link className="nav-links" as={Link} to="experience" smooth={true} duration={500} spy={true} offset={-70} onClick={() => setExpanded(false)}>Experience</Nav.Link> */}
            <Nav.Link className="nav-links" as={Link} to="work" smooth={true} duration={500} spy={true} offset={-70} onClick={() => setExpanded(false)}>Work</Nav.Link>
            <Nav.Link className="nav-links" as={Link} to="contact" smooth={true} duration={500} spy={true} offset={-70} onClick={() => setExpanded(false)}>Contact</Nav.Link>
            {/* <Nav.Link className="resumeBtn" href={Pdf} target="_blank">Resume</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
   </div>
  );
}

export default Navigation;
