import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Todo from "./assets/TimedAppLogo.png"
import WTP from "./assets/wtpLogo.png"
import MRLogo from "./assets/MatchRouletteLogo.png"
import Image from "react-bootstrap/Image";
import Button from 'react-bootstrap/Button'
import { VscGithubInverted } from "react-icons/vsc";
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import { FaAppStoreIos } from "react-icons/fa";


function Work() {
    return (
  <div class="section" id="work">
    <div class="pills p-2">
      <Zoom>
          <h1 className="primaryTxt text-center pb-5">App Development</h1>
      </Zoom>

      <Row className="centerRow pb-5">
          <Col sm={4}>
          <Slide right>
                <h3 className="secondaryTxt">So What's the Price</h3>
                <p className="secondaryTxt">A simple app to calculate how much to tip on a bill, and also calculate discount percentages off products.</p>
                
                <p className="secondaryTxt">Written in Swift, available on iOS, iPadOS, and Apple Watch</p>
                
                <br/>
                  <Button
                    variant="default"
                    style={{ color: "#D67A62", border: "2px solid #D67A62", margin: "5%" }}
                    size="lg"
                    href="https://apps.apple.com/us/app/sowhatstheprice/id1638209094"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on App Store
                  </Button>
                
          </Slide>
          </Col>
          
          <Col sm={4}>
          <Zoom>
            <a href="https://apps.apple.com/us/app/sowhatstheprice/id1638209094" target="_blank" rel="noreferrer">
              <Image src={WTP} fluid rounded/>
            </a> 
          </Zoom>
          </Col>
        </Row>

        <hr class="dashedLines"/>

        <Row className="centerRow pb-4">
          <Col sm={4} xs={{ order: 2 }} md={{ order: 1 }}>
            <Zoom>
              <a href="https://apps.apple.com/us/app/timed-todo/id1643593420" target="_blank" rel="noreferrer">
                <Image src={Todo} fluid rounded/>
              </a>  
            </Zoom>
            </Col>
          <Col sm={4} xs={{ order: 1 }} md={{ order: 2 }}>
            <Slide left>
            <h3 className="secondaryTxt pt-3">Timed Todo</h3>
                <p className="secondaryTxt">Todo app that tracks tasks by counting down a by a number of days, instead of to a certain date.</p>
                
                <p className="secondaryTxt">Written in Swift, available on iOS, iPadOS, and Apple Silicon Mac OS. Utilizes Cloudkit to sync users data across their iCloud accounts</p>
                
                <br/>
                  <Button
                    variant="default"
                    style={{ color: "#D67A62", border: "2px solid #D67A62", margin: "5%" }}
                    size="lg"
                    href="https://apps.apple.com/us/app/timed-todo/id1643593420"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on App Store
                  </Button>
            </Slide>     
          </Col>
        </Row>

        <hr class="dashedLines"/>

        <Row className="centerRow pb-5">
          <Col sm={4}>
          <Slide right>
                <h3 className="secondaryTxt">Match Roulette</h3>
                <p className="secondaryTxt">A random football match app to spice up your online football gaming.</p>
                
                <p className="secondaryTxt">Written in Swift, available on iOS, iPadOS, and Apple Silicon Mac OS</p>
                
                <br/>
                  <Button
                    variant="default"
                    style={{ color: "#D67A62", border: "2px solid #D67A62", margin: "5%" }}
                    size="lg"
                    href="https://apps.apple.com/us/app/match-roulette/id6447590985"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on App Store
                  </Button>
                
          </Slide>
          </Col>
          
          <Col sm={4}>
          <Zoom>
            <a href="https://apps.apple.com/us/app/match-roulette/id6447590985" target="_blank" rel="noreferrer">
              <Image src={MRLogo} fluid rounded/>
            </a> 
          </Zoom>
          </Col>
        </Row>

        <Row className="centerRow pb-4">
          
          <Col sm={4} >
            <Zoom>
            <div className="text-center">
              <p className="secondaryTxt">My App Store Preview</p>
              <a href="https://apps.apple.com/us/developer/cameron-baffuto/id1635183178" target="_blank" rel="noreferrer">
                <FaAppStoreIos className="git"/>  
              </a> 
            </div>
            </Zoom>
          </Col>
          <Col sm={4} >
            <Zoom>
            <div className="text-center">
            <p className="secondaryTxt">My Github</p>
              <a href="https://github.com/CameronBaffuto" target="_blank" rel="noreferrer">
                <VscGithubInverted className="git"/>  
              </a> 
            </div>
            </Zoom>
          </Col>
         
        </Row>

        </div>
      </div>
    
        
    )
}

export default Work

