import React from 'react'

function Footer() {

    const getCurrentYear = () => {
        return new Date().getFullYear() 
    }

    return (
        <div class="footer darkBlue">
            <p className="footerTxt text-center">Designed and Built by Cam Baffuto {getCurrentYear()}</p>
        </div>
    )
}

export default Footer
