import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import "./App.css";
import Splash from "./Splash";
import About from "./About";
// import Experience from "./Experience";
import Work from "./Work";
import Contact from "./Contact";
import Footer from "./Footer";
import { animateScroll as scroll } from "react-scroll";
import { BsFillArrowUpCircleFill } from "react-icons/bs";

function App() {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div>
    
    <Navigation />
    <Splash />
    <About />
    {/* <Experience /> */}
    <Work />
    <Contact />  
    <Footer /> 

    {isVisible && (
    <div class="arrowBottom">
        <BsFillArrowUpCircleFill onClick={() => scroll.scrollToTop()} className="arrow"/>
    </div> 
    )} 
   
    </div>
  );
}

export default App;