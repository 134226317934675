import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Avatar from "./assets/cam.png";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { Link } from "react-scroll";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import Fade from 'react-reveal/Fade';

function Splash() {
  return (
    
    <div class="section" id="home">
    <Fade top big>
      <Row>

      <Col md className="centerRow">
  
            <Image src={Avatar} fluid width="75%"/>
         
        </Col>

        <Col md style={{ margin: "5%" }}>
    
            <h1 className="headerTxt">Hi, I'm Cam</h1>
            <h2 className="primaryTxt">A Software Engineer</h2>
            <Link to="contact" smooth={true} duration={500} spy={true} offset={-70}>
              <Button variant="default" style={{ color: "#283C56", background: "#D67A62", margin: "5%" }} size="lg">
                Get in Touch
              </Button>
            </Link>
       
        </Col>

      </Row>

      <div class="text-center p-4">
  
      <Link to="about" smooth={true} duration={500} spy={true} offset={-70}>
        <BsFillArrowDownCircleFill className="arrow"/>
      </Link> 
    
      </div>
      </Fade>
    </div>
    
  );
}

export default Splash;
