import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Bounce from 'react-reveal/Bounce';

function Contact() {
  return (

    <div class="section" id="contact">
        <Row>
          <Col md>
          <Bounce bottom>
          <h2 className="primaryTxt text-center">Wanna know more?</h2>
          <p className="secondaryTxt text-center">Have an exciting project that could use  my  help? Send a message!</p>
          </Bounce>
          </Col>  
          <Col className="mx-4">
            <Bounce bottom>
            <Form name="contact v1" method="post" data-netlify="true">
            <input type="hidden" name="form-name" value="contact v1" />
              <Form.Group className="mb-3" controlId="name" id="name">
                <Form.Label className="primaryTxt">Name</Form.Label>
                <Form.Control type="text" name="name" placeholder="whats ya name?" style={{backgroundColor: "#1C324E", color: "white"}}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email" id="email">
                <Form.Label className="primaryTxt">Email address</Form.Label>
                <Form.Control type="email" name="email" placeholder="name@example.com" style={{backgroundColor: "#1C324E", color: "white"}}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="message" id="message">
                <Form.Label className="primaryTxt">Message</Form.Label>
                <Form.Control as="textarea" name="message" rows={3} placeholder="message..." style={{backgroundColor: "#1C324E", color: "white"}}/>
              </Form.Group>
              <Button variant="default" style={{ color: "#283C56", background: "#D67A62", margin: "5%" }} size="lg" type="submit">
                Submit
              </Button>
            </Form>
            </Bounce>
          </Col>
        </Row>
      </div>
  );
}

export default Contact;
